import {
  ADD_NEW_CATEGORY,
  ADD_NEW_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY_FAIL,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_DETAIL,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
} from "./actionTypes"

export const getCategoriesList = () => ({
  type: GET_CATEGORIES_LIST,
})

export const getCategoriesListSuccess = categories => ({
  type: GET_CATEGORIES_LIST_SUCCESS,
  payload: categories,
})

export const getCategoriesListFail = error => ({
  type: GET_CATEGORIES_LIST_FAIL,
  payload: error,
})

export const getCategoryDetail = categoryId => ({
  type: GET_CATEGORY_DETAIL,
  categoryId,
})

export const getCategoryDetailSuccess = categoryDetails => ({
  type: GET_CATEGORY_DETAIL_SUCCESS,
  payload: categoryDetails,
})

export const getCategoryDetailFail = error => ({
  type: GET_CATEGORY_DETAIL_FAIL,
  payload: error,
})

export const addNewCategory = (category, history) => ({
  type: ADD_NEW_CATEGORY,
  payload: { category, history },
})

export const addNewCategorySuccess = category => ({
  type: ADD_NEW_CATEGORY_SUCCESS,
  payload: category,
})

export const addNewCategoryFail = error => ({
  type: ADD_NEW_CATEGORY_FAIL,
  payload: error,
})

export const updateCategory = (id, category, history) => ({
  type: UPDATE_CATEGORY,
  payload: { id, category, history },
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const deleteCategory = category => ({
  type: DELETE_CATEGORY,
  category,
})

export const deleteCategorySuccess = category => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})
