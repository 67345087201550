import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { addNewVariation, getColorInVariation } from "store/actions"
import Dropzone from "react-dropzone"
import { useSelector } from "react-redux"
import { uploadFileVariation } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const AddVariation = props => {
  //meta title
  document.title = "Add Variation"
  const dispatch = useDispatch()
  const sizeChart = []
  const { id } = useParams()
  useEffect(() => {
    dispatch(getColorInVariation())
  }, [dispatch])

  const dataState = state => state.products.color.data
  const data = useSelector(dataState) || []

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      color_id: "",
      images: "",
      images_mobile: "",
      is_active: false,
    },

    validationSchema: Yup.object({
      color_id: Yup.string().required("Please Select Color"),
    }),

    onSubmit: async values => {
      let images, images_mobile, loadingSwal
      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
        })
        try {
          images = await uploadFileVariation(values.images)
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        }

        try {
          images_mobile = await uploadFileVariation(values.images_mobile)
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        }

        const value = {
          color_id: values.color_id || "",
          images: (images && images.data) || "",
          images_mobile: (images_mobile && images_mobile.data) || "",
          is_active: values.is_active || false,
        }

        const response = await dispatch(
          addNewVariation(id, value, props.router.navigate)
        )
        if (response.result) {
          loadingSwal.close()
        }
      } catch (error) {
        console.error(error)
        loadingSwal.close()
      }
    },
  })

  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFilesMobile, setSelectedFilesMobile] = useState([])

  function handleAcceptedFiles(value, files) {
    const updatedFiles = files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if (value === "Web") {
      setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles])
    } else if (value === "Mobile") {
      setSelectedFilesMobile(prevFiles => [...prevFiles, ...updatedFiles])
    }
  }

  function handleDeletedFiles(value, fileToDelete) {
    if (value === "Web") {
      setSelectedFiles(prevFiles =>
        prevFiles.filter(file => file !== fileToDelete)
      )
    } else if (value === "Mobile") {
      setSelectedFilesMobile(prevFiles =>
        prevFiles.filter(file => file !== fileToDelete)
      )
    }
  }

  useEffect(() => {
    validation.setFieldValue("images", selectedFiles)
  }, [selectedFiles])

  useEffect(() => {
    validation.setFieldValue("images_mobile", selectedFilesMobile)
  }, [selectedFilesMobile])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to={`/list-variation/${id}`} style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Add Variation
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">Color*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="color_id"
                            value={validation.values.color_id}
                            className={`form-select ${
                              validation.touched.color_id &&
                              validation.errors.color_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.color_id && (
                              <option>Select Color</option>
                            )}

                            {(data || []).map(item => (
                              <option key={item.id} value={item._id}>
                                {item.color_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.color_id &&
                          validation.errors.color_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.color_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Choose Images</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("Web", acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles("Web", f)
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Label>Choose Images For Mobile</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("Mobile", acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFilesMobile.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles("Mobile", f)
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddVariation)

AddVariation.propTypes = {
  history: PropTypes.object,
}
