export const GET_SIZES_LIST = "GET_SIZES_LIST"
export const GET_SIZES_LIST_SUCCESS = "GET_SIZES_LIST_SUCCESS"
export const GET_SIZES_LIST_FAIL = "GET_SIZES_LIST_FAIL"

export const GET_SIZE_DETAIL = "GET_SIZE_DETAIL"
export const GET_SIZE_DETAIL_SUCCESS = "GET_SIZE_DETAIL_SUCCESS"
export const GET_SIZE_DETAIL_FAIL = "GET_SIZE_DETAIL_FAIL"

export const ADD_NEW_SIZE = "ADD_NEW_SIZE"
export const ADD_NEW_SIZE_SUCCESS = "ADD_NEW_SIZE_SUCCESS"
export const ADD_NEW_SIZE_FAIL = "ADD_NEW_SIZE_FAIL"

export const UPDATE_SIZE = "UPDATE_SIZE"
export const UPDATE_SIZE_SUCCESS = "UPDATE_SIZE_SUCCESS"
export const UPDATE_SIZE_FAIL = "UPDATE_SIZE_FAIL"

export const DELETE_SIZE = "DELETE_SIZE"
export const DELETE_SIZE_SUCCESS = "DELETE_SIZE_SUCCESS"
export const DELETE_SIZE_FAIL = "DELETE_SIZE_FAIL"
