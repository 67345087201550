import React, { useState, useRef, useMemo, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./newsLetterListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { deleteSize } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getNewsletterList } from "store/newsletter/actions"

const newsLetterList = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getNewsletterList())
  }, [dispatch])

  const dataState = state => state.newsLetter.newsLetterList.data
  const data = useSelector(dataState) || []

  document.title = "News Letter Subscribers"
  const [isLoading, setLoading] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1"> {cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Email Address",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.email}
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteSize(deleteId.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            News Letter Subscribers
          </CardTitle>
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default newsLetterList
