import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  getCategoriesListForSubCategory,
  getSubCategoryDetail,
  updateSubCategory,
} from "store/actions"
import { uploadFileSubcategory } from "helpers/fakebackend_helper"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"

const AddSubCategory = props => {
  //meta title
  document.title = "Edit Category"
  const dispatch = useDispatch()
  let { id } = useParams()

  useEffect(() => {
    dispatch(getCategoriesListForSubCategory())
    dispatch(getSubCategoryDetail(id))
  }, [dispatch])

  const dropdownDataState = state => state.subcategories.categoryList.data
  const dropdownData = useSelector(dropdownDataState) || []

  const dataState = state => state.subcategories.subcategoryDetail.data
  const data = useSelector(dataState) || []
  function isValidUrl(string) {
    try {
      new URL(string)
      return true
    } catch (error) {
      return false
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      categoryId: (data && data.category) || "",
      subcategory_name: (data && data.subcategory_name) || "",
      description: (data && data.description) || "",
      full_pic: (data && data.full_pic) || "",
      thumbnail_pic: (data && data.thumbnail_pic) || "",
      thumbnail_pic_mobile: (data && data.thumbnail_pic_mobile) || "",
      full_pic_mobile: (data && data.full_pic_mobile) || "",
      is_active: (data && data.is_active) || false,
    },

    validationSchema: Yup.object({
      categoryId: Yup.string().required("Please Select Subcategory"),
      subcategory_name: Yup.string().required("Please Enter Subcategory Name"),
      description: Yup.string().required("Please Enter Description"),
    }),

    onSubmit: async values => {
      const value = {
        categoryId: values.categoryId || "",
        subcategory_name: values.subcategory_name || "",
        description: values.description || "",
        // full_pic: (full_pic && full_pic.data) || values.full_pic,
        // thumbnail_pic: (thumbnail_pic && thumbnail_pic.data) || values.thumbnail_pic,
        is_active: values.is_active || false,
      }
      let loadingSwal
      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
        })

        try {
          const parseThumbnailUrl =
            values.thumbnail_pic && isValidUrl(values.thumbnail_pic)
              ? new URL(values.thumbnail_pic)
              : null
          if (
            !["http:", "https:", "blob:"].includes(parseThumbnailUrl?.protocol)
          ) {
            const result = await uploadFileSubcategory(values.thumbnail_pic)
            value.thumbnail_pic = result.data[0]
          }
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }
        try {
          const parseFullPicUrl =
            values.full_pic && isValidUrl(values.full_pic)
              ? new URL(values.full_pic)
              : null
          if (
            !["http:", "https:", "blob:"].includes(parseFullPicUrl?.protocol)
          ) {
            const result = await uploadFileSubcategory(values.full_pic)
            value.full_pic = result.data[0]
          }
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }
        try {
          const parseThumbnailMobileUrl =
            values.thumbnail_pic_mobile &&
            isValidUrl(values.thumbnail_pic_mobile)
              ? new URL(values.thumbnail_pic_mobile)
              : null
          if (
            !["http:", "https:", "blob:"].includes(
              parseThumbnailMobileUrl?.protocol
            )
          ) {
            const result = await uploadFileSubcategory(
              values.thumbnail_pic_mobile
            )
            value.thumbnail_pic_mobile = result.data[0]
          }
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }
        try {
          const parseFullPicMobileUrl =
            values.full_pic_mobile && isValidUrl(values.full_pic_mobile)
              ? new URL(values.full_pic_mobile)
              : null
          if (
            !["http:", "https:", "blob:"].includes(
              parseFullPicMobileUrl?.protocol
            )
          ) {
            const result = await uploadFileSubcategory(values.full_pic_mobile)
            value.full_pic_mobile = result.data[0]
          }
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }

        const response = dispatch(
          updateSubCategory(id, value, props.router.navigate)
        )
        if (response.result) {
          loadingSwal.close()
        }
      } catch (error) {
        loadingSwal.close()
        console.error(error)
      } finally {
        loadingSwal.close()
      }
    },
  })

  const [thumbnailFiles, setThumbnailFiles] = useState([])
  const [fullPic, setFullPic] = useState([])
  const [thumbnailMobile, setThumbnailMobile] = useState([])
  const [fullPicMobile, setFullPicMobile] = useState([])
  function handleAcceptedFiles(value, files) {
    if (files.length > 0) {
      const file = files[0]
      const updatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
      if (value === "Thumbnail") {
        setThumbnailFiles([updatedFile])
      } else if (value === "FullPic") {
        setFullPic([updatedFile])
      } else if (value === "ThumbnailMobile") {
        setThumbnailMobile([updatedFile])
      } else if (value === "FullPicMobile") {
        setFullPicMobile([updatedFile])
      }
    }
  }

  function handleDelete(value) {
    if (value === "Thumbnail") {
      setThumbnailFiles([])
    } else if (value === "FullPic") {
      setFullPic([])
    } else if (value === "ThumbnailMobile") {
      setThumbnailMobile([])
    } else if (value === "FullPicMobile") {
      setFullPicMobile([])
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  useEffect(() => {
    if (data) {
      setThumbnailFiles(data.thumbnail_pic)
      setFullPic(data.full_pic)
      setThumbnailMobile(data.thumbnail_pic_mobile)
      setFullPicMobile(data.full_pic_mobile)
    }
  }, [data])

  useEffect(() => {
    validation.setFieldValue("thumbnail_pic", thumbnailFiles)
  }, [thumbnailFiles])

  useEffect(() => {
    validation.setFieldValue("full_pic", fullPic)
  }, [fullPic])

  useEffect(() => {
    validation.setFieldValue("full_pic_mobile", fullPicMobile)
  }, [fullPicMobile])

  useEffect(() => {
    validation.setFieldValue("thumbnail_pic_mobile", thumbnailMobile)
  }, [thumbnailMobile])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-subcategories" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Edit Subcategory
          </CardTitle>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Category*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="categoryId"
                            value={validation.values.categoryId}
                            className={`form-select ${
                              validation.touched.categoryId &&
                              validation.errors.categoryId
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.categoryId && (
                              <option>Select Category</option>
                            )}

                            {(dropdownData || []).map(item => (
                              <option key={item.id} value={item._id}>
                                {item.category_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.categoryId &&
                          validation.errors.categoryId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.categoryId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="category">Subcategory Name*</Label>
                          <Input
                            id="subcategory_name"
                            name="subcategory_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Subcategory Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.subcategory_name &&
                              validation.errors.subcategory_name
                                ? true
                                : false
                            }
                            value={validation.values.subcategory_name}
                          />
                          {validation.touched.subcategory_name &&
                          validation.errors.subcategory_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.subcategory_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Description*</Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="description"
                            placeholder="Enter Description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                            value={validation.values.description}
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Choose Thumbnail Picture</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("Thumbnail", acceptedFiles)
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop file here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {thumbnailFiles && thumbnailFiles?.length > 0 && (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={thumbnailFiles[0].name}
                                        src={
                                          thumbnailFiles[0].preview ||
                                          validation.values.thumbnail_pic
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {thumbnailFiles[0].name ===
                                        "current_thumbnail"
                                          ? "Existing Thumbnail"
                                          : thumbnailFiles[0].name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {thumbnailFiles[0].formattedSize}
                                        </strong>
                                      </p>
                                    </Col>
                                    <Col className="text-end">
                                      <Button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          handleDelete("Thumbnail")
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Label>Choose Full Picture</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("FullPic", acceptedFiles)
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop file here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {fullPic && fullPic?.length > 0 && (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={fullPic[0].name}
                                        src={
                                          fullPic[0].preview ||
                                          validation.values.full_pic
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {fullPic[0].name === "current_thumbnail"
                                          ? "Existing Thumbnail"
                                          : fullPic[0].name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {fullPic[0].formattedSize}
                                        </strong>
                                      </p>
                                    </Col>
                                    <Col className="text-end">
                                      <Button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          handleDelete("FullPic")
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Choose Thumbnail Picture Mobile</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(
                                "ThumbnailMobile",
                                acceptedFiles
                              )
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop file here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {thumbnailMobile && thumbnailMobile?.length > 0 && (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={thumbnailMobile[0].name}
                                        src={
                                          thumbnailMobile[0].preview ||
                                          validation.values.thumbnail_pic_mobile
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {thumbnailMobile[0].name ===
                                        "current_thumbnail"
                                          ? "Existing Thumbnail"
                                          : thumbnailMobile[0].name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {thumbnailMobile[0].formattedSize}
                                        </strong>
                                      </p>
                                    </Col>
                                    <Col className="text-end">
                                      <Button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          handleDelete("ThumbnailMobile")
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Label>Choose Full Picture Mobile</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(
                                "FullPicMobile",
                                acceptedFiles
                              )
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop file here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {fullPicMobile && fullPicMobile?.length > 0 && (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={fullPicMobile[0].name}
                                        src={
                                          fullPicMobile[0].preview ||
                                          validation.values.full_pic_mobile
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {fullPicMobile[0].name ===
                                        "current_thumbnail"
                                          ? "Existing Thumbnail"
                                          : fullPicMobile[0].name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {fullPicMobile[0].formattedSize}
                                        </strong>
                                      </p>
                                    </Col>
                                    <Col className="text-end">
                                      <Button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          handleDelete("FullPicMobile")
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Edit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddSubCategory)

AddSubCategory.propTypes = {
  history: PropTypes.object,
}
