import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ColorPicker, useColor } from "react-color-palette"
import "react-color-palette/css"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { addNewColor } from "store/actions"
//Import Breadcrumb

const AddColor = props => {
  const [color, setColor] = useColor("")
  const dispatch = useDispatch()

  document.title = "Add Color"

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      color_name: "",
      color_hexacode: (color && color.hex) || "#000000",
      is_active: true,
    },

    validationSchema: Yup.object({
      color_name: Yup.string().required("Please Enter Color Name"),
      color_hexacode: Yup.string().required("Please Select Color"),
    }),

    onSubmit: async values => {
      dispatch(addNewColor(values, props.router.navigate))
    },
  })

  useEffect(() => {
    if (color) {
      validation.setFieldValue("color_hexacode", color.hex)
    }
  }, [color])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-colors" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Add Color
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {" "}
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="color_name">Color Name*</Label>
                          <Input
                            id="color_name"
                            name="color_name"
                            type="text"
                            className="form-control"
                            placeholder="Color Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.color_name &&
                              validation.errors.color_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.color_name &&
                          validation.errors.color_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.color_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Choose Color</Label>
                          <ColorPicker
                            hideInput={["rgb", "hsv"]}
                            color={color}
                            onChange={setColor}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddColor)

AddColor.propTypes = {
  history: PropTypes.object,
}
