import {
  GET_NEWSLETTER_LIST,
  GET_NEWSLETTER_LIST_FAIL,
  GET_NEWSLETTER_LIST_SUCCESS,
} from "./actionTypes"

export const getNewsletterList = () => ({
  type: GET_NEWSLETTER_LIST,
})

export const getNewsletterListSuccess = sizes => ({
  type: GET_NEWSLETTER_LIST_SUCCESS,
  payload: sizes,
})

export const getNewsletterListFail = error => ({
  type: GET_NEWSLETTER_LIST_FAIL,
  payload: error,
})
