import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
import ReactApexChart from "react-apexcharts"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  CardBody,
  Table,
  Row,
  Label,
  FormFeedback,
  Badge,
} from "reactstrap"
import { useFormik } from "formik"
// import NewCustomerModal from "../Modal/newCustomerModal"
// import NewUnitsAssignedModal from "../Modal/newUnitsAssignedModal"
// import NewBillsModal from "../Modal/newBillsModal"
// import PaymentsModal from "../Modal/paymentsModal"
// import { ActivityFilter } from "store/actions"
import AmountModal from "./Modals/businessAmountModal"
import NewCustomer from "./Modals/newCustomerModal"
import NewOrder from "./Modals/newOrderModal"
import NewProduct from "./Modals/newProductModal"
import { useDispatch, useSelector } from "react-redux"

const category = props => {
  const dispatch = useDispatch()
  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const [modal_large2, setmodal_large2] = useState(false)
  const [modalLarge2, setModalLarge2] = useState(false)

  const toggleModalLarge2 = () => setModalLarge2(!modalLarge2)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large2(!modal_large2)
    removeBodyCss()
  }

  const [modal_large3, setmodal_large3] = useState(false)
  const [modalLarge3, setModalLarge3] = useState(false)

  const toggleModalLarge3 = () => setModalLarge3(!modalLarge3)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large3(!modal_large3)
    removeBodyCss()
  }

  const [modal_large4, setmodal_large4] = useState(false)
  const [modalLarge4, setModalLarge4] = useState(false)

  const toggleModalLarge4 = () => setModalLarge4(!modalLarge4)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large4(!modal_large4)
    removeBodyCss()
  }
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed

    initialValues: {
      filter: "This Year",
    },

    validationSchema: Yup.object({}),
  })

  // useEffect(() => {
  //   dispatch(ActivityFilter({ filter: validation.values.filter }))
  // }, [validation.values.filter])

  // const countState = state => state.Dashboard.activityCount.counts
  // const count = useSelector(countState) || []
  const count = []

  const formatNumberWithCommas = number => {
    if (!number) {
      return number
    }

    let numStr = String(number)

    let parts = numStr.split(".")

    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3)
    let remainingDigits = integerPart.slice(0, -3)
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    return formattedInteger
  }

  useEffect(() => {
    props.setData({ activityFilter: validation.values.filter })
  }, [validation.values.filter])

  return (
    <React.Fragment>
      <NewCustomer
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        data={props.detail.newCustomerData}
      />
      <NewOrder
        isOpen={modalLarge2}
        toggle={toggleModalLarge2}
        data={props.detail.orderData}
      />
      <AmountModal
        isOpen={modalLarge3}
        toggle={toggleModalLarge3}
        data={props.detail.businessData}
      />
      <NewProduct
        isOpen={modalLarge4}
        toggle={toggleModalLarge4}
        data={props.detail.productData}
      />

      <Col>
        <Card style={{ height: "476px" }}>
          <CardBody>
            <Row>
              <Col className="d-flex align-items-center">
                <h4 className="card-title mb-4">Activity</h4>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <select
                    name="filter"
                    className={`form-select ${
                      validation.touched.filter && validation.errors.filter
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.filter}
                  >
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                    <option value="This Year">This Year</option>
                    <option value="Previous Week">Previous Week</option>
                    <option value="Previous Month">Previous Month</option>
                    <option value="Previous Year">Previous Year</option>
                  </select>
                  {validation.touched.filter && validation.errors.filter ? (
                    <FormFeedback type="invalid">
                      {validation.errors.filter}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="table-responsive mt-4">
              <Table className="table align-middle mb-2">
                <tbody>
                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">New Customer</h5>
                      <p className="text-muted mb-0">
                        Represents the number of new customers acquired.
                      </p>
                    </td>
                    <td>
                      {/* <div id="radialchart-1">
                        <ReactApexChart
                          options={getChartOptions(1)}
                          series={[45]}
                          type="radialBar"
                          height={66}
                          width={60}
                          className="apex-charts"
                        />
                      </div> */}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={toggleModalLarge}
                    >
                      <p className="text-muted mb-1">Customers</p>
                      <h5 className="mb-0 text-primary">
                        {props.detail.newCustomerCount}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16  mb-1">Orders</h5>
                      <p className="text-muted mb-0">
                        Indicates the number of orders placed by customers.
                      </p>
                    </td>
                    <td>
                      {/* <div id="radialchart-2">
                        <ReactApexChart
                          options={getChartOptions(2)}
                          series={[60]}
                          type="radialBar"
                          height={66}
                          width={60}
                          className="apex-charts"
                        />
                      </div> */}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={toggleModalLarge2}
                    >
                      <p className="text-muted mb-1">Orders</p>
                      <h5 className="mb-0 mb-0 text-warning">
                        {props.detail.orderCount}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16  mb-1">Business</h5>
                      <p className="text-muted mb-0 ">
                        Denotes the total Amount of sales.
                      </p>
                    </td>
                    <td>
                      {/* <div id="radialchart-3">
                        <ReactApexChart
                          options={getChartOptions(3)}
                          series={[75]}
                          type="radialBar"
                          height={66}
                          width={60}
                          className="apex-charts"
                        />
                      </div> */}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={toggleModalLarge3}
                    >
                      <p className="text-muted mb-1">Amount</p>
                      <h5 className="mb-0 mb-0 text-danger">
                        ₹{formatNumberWithCommas(props.detail.businessAmount)}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">Products</h5>
                      <p className="text-muted mb-0">
                        Reflects the total number of Products Created.
                      </p>
                    </td>
                    <td>
                      {/* <div id="radialchart-4">
                        <ReactApexChart
                          options={getChartOptions(4)}
                          series={[30]} // COUNT VALUE
                          type="radialBar"
                          height={66}
                          width={60}
                          className="apex-charts"
                        />
                      </div> */}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={toggleModalLarge4}
                    >
                      <p className="text-muted mb-1">Products</p>
                      <h5 className="mb-0 mb-0 text-success">
                        {props.detail.productCount}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default category
