import {
  GET_CONTACT_US_LIST,
  GET_CONTACT_US_LIST_SUCCESS,
  GET_CONTACT_US_LIST_FAIL,
} from "./actionTypes"

export const getContactUsList = () => ({
  type: GET_CONTACT_US_LIST,
})

export const getContactUsListSuccess = users => ({
  type: GET_CONTACT_US_LIST_SUCCESS,
  payload: users,
})

export const getContactUsListFail = error => ({
  type: GET_CONTACT_US_LIST_FAIL,
  payload: error,
})
