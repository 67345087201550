import {
  ADD_NEW_SIZE,
  ADD_NEW_SIZE_SUCCESS,
  ADD_NEW_SIZE_FAIL,
  GET_SIZES_LIST,
  GET_SIZES_LIST_SUCCESS,
  GET_SIZES_LIST_FAIL,
  UPDATE_SIZE,
  UPDATE_SIZE_SUCCESS,
  UPDATE_SIZE_FAIL,
  DELETE_SIZE,
  DELETE_SIZE_SUCCESS,
  DELETE_SIZE_FAIL,
  GET_SIZE_DETAIL,
  GET_SIZE_DETAIL_SUCCESS,
  GET_SIZE_DETAIL_FAIL,
} from "./actionTypes"

export const getSizesList = () => ({
  type: GET_SIZES_LIST,
})

export const getSizesListSuccess = sizes => ({
  type: GET_SIZES_LIST_SUCCESS,
  payload: sizes,
})

export const getSizesListFail = error => ({
  type: GET_SIZES_LIST_FAIL,
  payload: error,
})

export const getSizeDetail = sizeId => ({
  type: GET_SIZE_DETAIL,
  sizeId,
})

export const getSizeDetailSuccess = sizeDetails => ({
  type: GET_SIZE_DETAIL_SUCCESS,
  payload: sizeDetails,
})

export const getSizeDetailFail = error => ({
  type: GET_SIZE_DETAIL_FAIL,
  payload: error,
})

export const addNewSize = (size, history) => ({
  type: ADD_NEW_SIZE,
  payload: { size, history },
})

export const addNewSizeSuccess = size => ({
  type: ADD_NEW_SIZE_SUCCESS,
  payload: size,
})

export const addNewSizeFail = error => ({
  type: ADD_NEW_SIZE_FAIL,
  payload: error,
})

export const updateSize = (id, size, history) => ({
  type: UPDATE_SIZE,
  payload: { id, size, history },
})

export const updateSizeSuccess = size => ({
  type: UPDATE_SIZE_SUCCESS,
  payload: size,
})

export const updateSizeFail = error => ({
  type: UPDATE_SIZE_FAIL,
  payload: error,
})

export const deleteSize = size => ({
  type: DELETE_SIZE,
  size,
})

export const deleteSizeSuccess = size => ({
  type: DELETE_SIZE_SUCCESS,
  payload: size,
})

export const deleteSizeFail = error => ({
  type: DELETE_SIZE_FAIL,
  payload: error,
})
