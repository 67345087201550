import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import MedilinenLogo from "../../assets/Medilinen/FullLogo.jpg"

const ForgetPasswordPage = props => {
  //meta title
  document.title = "Forget Password | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
    },
  })

  const selectForgotPasswordState = state => state.ForgetPassword
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    forgetPassword => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  )

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  )

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div>
                  <Row>
                    <Col xs={5} className="align-self-end">
                      <img src={MedilinenLogo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Link to="/login">
                    <div className=" align-item-center">
                      <p className="text-muted">
                        <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
                        Back to Login
                      </p>
                    </div>
                  </Link>
                  <div className="p-2">
                    <CardTitle className="mb-4" style={{ fontSize: "20px" }}>
                      Forgot Password?
                    </CardTitle>

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        //     validation.handleSubmit();
                        //     return false;
                      }}
                    >
                      <Row>
                        <div className="mb-3">
                          <Input
                            name="email_address"
                            className="form-control"
                            placeholder="Enter Your Email Address"
                            type="email_address"
                          />
                        </div>
                      </Row>

                      <Row className="align-items-center mt-3">
                        <Col className="text-start">
                          <button
                            className="w-md "
                            type="submit"
                            style={{
                              backgroundColor: "#082a6b",
                              color: "#ffffff",
                              borderTopRightRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              height: "35px",
                            }}
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
