export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST"
export const GET_CATEGORIES_LIST_SUCCESS = "GET_CATEGORIES_LIST_SUCCESS"
export const GET_CATEGORIES_LIST_FAIL = "GET_CATEGORIES_LIST_FAIL"

export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL"
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS"
export const GET_CATEGORY_DETAIL_FAIL = "GET_CATEGORY_DETAIL_FAIL"

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_NEW_CATEGORY_SUCCESS = "ADD_NEW_CATEGORY_SUCCESS"
export const ADD_NEW_CATEGORY_FAIL = "ADD_NEW_CATEGORY_FAIL"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"
