import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { addNewSizeChart, getSizesForSizeChart } from "store/actions"
import Dropzone from "react-dropzone"
import Swal from "sweetalert2"
import { uploadFileSizechart } from "helpers/fakebackend_helper"

const AddSizeChart = props => {
  document.title = "Add Size Chart"
  const dispatch = useDispatch()
  const [sizeChartImage, setSizeChartImage] = useState([])

  useEffect(() => {
    dispatch(getSizesForSizeChart())
  }, [dispatch])

  const dataState = state => state.sizeChart.sizeForChart.data
  const data = useSelector(dataState) || []

  const rangeOrSingleNumberRegex =
    /^(0|[1-9]\d*)(\.\d+)?\s*-\s*(0|[1-9]\d*)(\.\d+)?$|^(0|[1-9]\d*)(\.\d+)?$/

  const trimAndMatch = (regex, message) => {
    return Yup.string()
      .transform(value => value.trim())
      .matches(regex, message)
  }

  const sizeTitles = data.map(item => item.size_title || "")

  const validation = useFormik({
    initialValues: {
      size_chart_title: "",
      top_bottom: "",
      size_chart_image: "",
      sizes:
        data.map(item => ({
          size_id: item._id || "",
          title: item.size_title || "",
          chest: item.chest || 0,
          shoulder: item.shoulder || 0,
          length: item.length || 0,
          hips: item.hips || 0,
          waist: item.waist || 0,
        })) || [],
      is_active: true,
    },

    validationSchema: Yup.object({
      size_chart_title: Yup.string().required("Please Enter Size Chart Title"),
      sizes: Yup.array().of(
        Yup.object().shape({
          chest: trimAndMatch(
            rangeOrSingleNumberRegex,
            "Chest must be a number or a range in the format '10-20' or '10'"
          ).required("Chest measurement is required"),
          shoulder: trimAndMatch(
            rangeOrSingleNumberRegex,
            "Shoulder must be a number or a range in the format '10-20' or '10'"
          ).required("Shoulder measurement is required"),
          length: trimAndMatch(
            rangeOrSingleNumberRegex,
            "Length must be a number or a range in the format '10-20' or '10'"
          ).required("Length measurement is required"),
          hips: trimAndMatch(
            rangeOrSingleNumberRegex,
            "Hips must be a number or a range in the format '10-20' or '10'"
          ).required("Hips measurement is required"),
          waist: trimAndMatch(
            rangeOrSingleNumberRegex,
            "Waist must be a number or a range in the format '10-20' or '10'"
          ).required("Waist measurement is required"),
        })
      ),
    }),

    onSubmit: async values => {
      let size_chart_image, loadingSwal

      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
        })

        try {
          size_chart_image = await uploadFileSizechart({
            file: values.size_chart_image,
          })
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }
        const value = {
          size_chart_title: values.size_chart_title || "",
          top_bottom: values.top_bottom || "",
          size_chart_image:
            (size_chart_image && size_chart_image.data[0]) || "",
          sizes: values.sizes || [],
          is_active: values.is_active || true,
        }

        const response = await dispatch(
          addNewSizeChart(value, props.router.navigate)
        )

        if (response.result) {
          loadingSwal.close()
        }
      } catch (error) {
        loadingSwal.close()
        console.error(error)
      } finally {
        loadingSwal.close()
      }
    },
    enableReinitialize: true,
  })

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSizeChartImage(files)
  }

  function handleDeletedFiles(fileToDelete) {
    setSizeChartImage(prevFiles =>
      prevFiles.filter(file => file !== fileToDelete)
    )
  }

  useEffect(() => {
    validation.setFieldValue("size_chart_image", sizeChartImage)
  }, [sizeChartImage])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-size-chart" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Add Size Chart
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">
                            Size Chart Title*
                          </Label>
                          <Input
                            id="size_chart_title"
                            name="size_chart_title"
                            type="text"
                            className="form-control"
                            placeholder="Enter Size Title"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.size_chart_title &&
                              validation.errors.size_chart_title
                            }
                          />
                          {validation.touched.size_chart_title &&
                          validation.errors.size_chart_title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.size_chart_title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Select Top / Bottom*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="top_bottom"
                            value={validation.values.top_bottom}
                            className={`form-select ${
                              validation.touched.top_bottom &&
                              validation.errors.top_bottom
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Top / Bottom</option>
                            <option value={"Top"}>Top</option>
                            <option value={"Bottom"}>Bottom</option>
                          </select>
                          {validation.touched.top_bottom &&
                          validation.errors.top_bottom ? (
                            <FormFeedback type="invalid">
                              {validation.errors.top_bottom}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {validation.values.top_bottom === "Top" && (
                      <Row>
                        <Label className="control-label mb-3">
                          Enter Top Sizes:
                        </Label>
                        <Row>
                          <Col lg={3}>
                            <Label>Size</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Chest</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Shoulder</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Length</Label>
                          </Col>
                        </Row>
                        {validation.values.sizes &&
                          validation.values.sizes.map((row, key) => (
                            <Row key={row.size_id}>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`description_${key}`}
                                    name={`sizes[${key}].size_title`}
                                    disabled
                                    value={sizeTitles && sizeTitles[key]}
                                  />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`chest_${key}`}
                                    name={`sizes[${key}].chest`}
                                    placeholder="Enter Chest Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.chest || ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]?.chest &&
                                      !!validation.errors.sizes?.[key]?.chest
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.chest &&
                                    validation.errors.sizes?.[key]?.chest && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].chest}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`shoulder_${key}`}
                                    name={`sizes[${key}].shoulder`}
                                    placeholder="Enter Shoulder Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.shoulder ||
                                      ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]
                                        ?.shoulder &&
                                      !!validation.errors.sizes?.[key]?.shoulder
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.shoulder &&
                                    validation.errors.sizes?.[key]
                                      ?.shoulder && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].shoulder}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`length_${key}`}
                                    name={`sizes[${key}].length`}
                                    placeholder="Enter Length Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.length || ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]?.length &&
                                      !!validation.errors.sizes?.[key]?.length
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.length &&
                                    validation.errors.sizes?.[key]?.length && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].length}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          ))}
                      </Row>
                    )}
                    {validation.values.top_bottom === "Bottom" && (
                      <Row>
                        <Label className="control-label mb-3">
                          Enter Bottom Sizes:
                        </Label>
                        <Row>
                          <Col lg={3}>
                            <Label>Size</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Hips</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Waist</Label>
                          </Col>
                          <Col lg={3}>
                            <Label>Length</Label>
                          </Col>
                        </Row>
                        {validation.values.sizes &&
                          validation.values.sizes.map((row, key) => (
                            <Row key={row.size_id}>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`description_${key}`}
                                    name={`sizes[${key}].size_title`}
                                    disabled
                                    value={sizeTitles && sizeTitles[key]}
                                  />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`hips_${key}`}
                                    name={`sizes[${key}].hips`}
                                    placeholder="Enter Hips Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.hips || ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]?.hips &&
                                      !!validation.errors.sizes?.[key]?.hips
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.hips &&
                                    validation.errors.sizes?.[key]?.hips && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].hips}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`waist_${key}`}
                                    name={`sizes[${key}].waist`}
                                    placeholder="Enter Waist Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.waist || ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]?.waist &&
                                      !!validation.errors.sizes?.[key]?.waist
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.waist &&
                                    validation.errors.sizes?.[key]?.waist && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].waist}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`length_${key}`}
                                    name={`sizes[${key}].length`}
                                    placeholder="Enter Length Size"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.sizes[key]?.length || ""
                                    }
                                    invalid={
                                      validation.touched.sizes?.[key]?.length &&
                                      !!validation.errors.sizes?.[key]?.length
                                    }
                                  />
                                  {validation.touched.sizes?.[key]?.length &&
                                    validation.errors.sizes?.[key]?.length && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sizes[key].length}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          ))}
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <Label>Choose Size Chart Picture</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {sizeChartImage.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles(f)
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md"
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddSizeChart)

AddSizeChart.propTypes = {
  history: PropTypes.object,
}
