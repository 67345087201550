import {
  GET_ECOM_USERS_LIST_SUCCESS,
  GET_ECOM_USERS_LIST_FAIL,
} from "./actionTypes"

const initialState = {
  userList: [],
  error: {},
  loading: false,
}

const ecomusers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ECOM_USERS_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      }

    case GET_ECOM_USERS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default ecomusers
