import React, { useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { addNewUser } from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"

const AddUser = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  document.title = "Add Admin User"
  const dispatch = useDispatch()
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/ // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      full_name: "",
      phone_no: "",
      email: "",
      password: "",
      username: "",
      designation: "",
    },

    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      phone_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Phone Number must be between 10 to 12 digits"
        )
        .required("Please Enter Phone Number "),
      email: Yup.string().email().required("Please Enter Email"),
      password: Yup.string()
        .matches(passwordRules, {
          message:
            "Please create a stronger password, Password must contain minimum 5 characters, 1 uppercase, 1 lowercase & 1 number",
        })
        .required("Please Enter Password"),
      username: Yup.string().required("Please Enter Username"),
      designation: Yup.string().required("Please Enter Designation"),
    }),

    onSubmit: async values => {
      dispatch(addNewUser(values, props.router.navigate))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-users" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Add Admin User
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {" "}
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="size">Full Name*</Label>
                          <Input
                            id="full_name"
                            name="full_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.full_name &&
                              validation.errors.full_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.full_name &&
                          validation.errors.full_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.full_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Phone Number*</Label>
                          <Input
                            id="phone_no"
                            name="phone_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone_no &&
                              validation.errors.phone_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone_no &&
                          validation.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="size">Email*</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="password">Password*</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <div className="col">
                              <Input
                                type={passwordShow ? "text" : "password"}
                                id="password"
                                placeholder="Create Password"
                                name="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                className={`form-control ${
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div>
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Username*</Label>
                          <Input
                            id="username"
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder="Enter Username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Designation*</Label>
                          <Input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            placeholder="Enter Designation"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.designation &&
                              validation.errors.designation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                          onSubmit={validation.handleSubmit}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddUser)

AddUser.propTypes = {
  history: PropTypes.object,
}
