import React, { useState, useRef, useMemo, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./ColorListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { deleteColor, getColorsList } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const colorList = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getColorsList())
  }, [dispatch])

  const dataState = state => state.colors.colorList.data
  const data = useSelector(dataState) || []

  const navigate = useNavigate()
  document.title = "Colors List "
  const [isLoading, setLoading] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Color Name",
        accessor: "color_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.color_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Choosen Color",
        accessor: "color_hexacode",
        filterable: true,
        Cell: cellProps => {
          const color = cellProps.row.original.color_hexacode
          return (
            <>
              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: color,
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></div>
                <h5 className="font-size-14 mt-1">{color}</h5>
              </div>
            </>
          )
        },
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.is_active === true ? "Yes" : "No"}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/edit-color/${cellProps.row.original._id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original._id
                  onClickDelete(userData)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteColor(deleteId.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">Colors List</CardTitle>
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default colorList
