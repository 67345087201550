import {
  ADD_NEW_COLOR_SUCCESS,
  ADD_NEW_COLOR_FAIL,
  GET_COLORS_LIST_SUCCESS,
  GET_COLORS_LIST_FAIL,
  GET_COLOR_DETAIL_SUCCESS,
  GET_COLOR_DETAIL_FAIL,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_FAIL,
  DELETE_COLOR_SUCCESS,
  DELETE_COLOR_FAIL,
} from "./actionTypes"

const initialState = {
  colorList: [],
  colors: [],
  colorDetail: {},
  error: {},
  loading: false,
}

const colors = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLORS_LIST_SUCCESS:
      return {
        ...state,
        colorList: action.payload,
        loading: false,
      }

    case GET_COLORS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COLOR_DETAIL_SUCCESS:
      return {
        ...state,
        colorDetail: action.payload,
        loading: false,
      }

    case GET_COLOR_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_COLOR_SUCCESS:
      return {
        ...state,
        colors: [...state.colors, action.payload],
        loading: false,
      }

    case ADD_NEW_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COLOR_SUCCESS:
      return {
        ...state,
        colorDetail: {
          ...state.colorDetail,
          data: {
            ...state.colorDetail.data,
            [action.payload.data._id]: {
              ...state.colorDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COLOR_SUCCESS:
      return {
        ...state,
        colorList: state.colorList.data.filter(
          color => color._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default colors
