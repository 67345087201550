export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS"
export const GET_ALL_ORDERS_FAIL = "GET_ALL_ORDERS_FAIL"

export const ADD_TRACKING_DETAIL = "ADD_TRACKING_DETAIL"
export const ADD_TRACKING_DETAIL_SUCCESS = "ADD_TRACKING_DETAIL_SUCCESS"
export const ADD_TRACKING_DETAIL_FAIL = "ADD_TRACKING_DETAIL_FAIL"

export const GET_DELIVERED_ORDERS = "GET_DELIVERED_ORDERS"
export const GET_DELIVERED_ORDERS_SUCCESS = "GET_DELIVERED_ORDERS_SUCCESS"
export const GET_DELIVERED_ORDERS_FAIL = "GET_DELIVERED_ORDERS_FAIL"
