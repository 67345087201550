import {
  GET_ECOM_USERS_LIST,
  GET_ECOM_USERS_LIST_SUCCESS,
  GET_ECOM_USERS_LIST_FAIL,
} from "./actionTypes"

export const getEcomUsersList = id => ({
  type: GET_ECOM_USERS_LIST,
  id,
})

export const getEcomUsersListSuccess = users => ({
  type: GET_ECOM_USERS_LIST_SUCCESS,
  payload: users,
})

export const getEcomUsersListFail = error => ({
  type: GET_ECOM_USERS_LIST_FAIL,
  payload: error,
})
