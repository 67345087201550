import {
  ADD_NEW_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY_FAIL,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_FAIL,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from "./actionTypes"

const initialState = {
  categoryList: [],
  categories: [],
  categoryDetail: {},
  error: {},
  loading: false,
}

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
        loading: false,
      }

    case GET_CATEGORIES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload,
        loading: false,
      }

    case GET_CATEGORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        loading: false,
      }

    case ADD_NEW_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryDetail: {
          ...state.categoryDetail,
          data: {
            ...state.categoryDetail.data,
            [action.payload.data._id]: {
              ...state.categoryDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: state.categoryList.data.filter(
          category => category._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default categories
