import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_USER,
  DELETE_USER,
  GET_USERS_LIST,
  GET_USER_DETAIL,
  UPDATE_USER,
} from "./actionTypes"
import {
  addNewUserFail,
  addNewUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUserDetailFail,
  getUserDetailSuccess,
  getUsersListFail,
  getUsersListSuccess,
  updateUserFail,
  updateUserSuccess,
} from "./actions"
import {
  addNewUser,
  deleteUser,
  getUserDetails,
  getUsersList,
  updateUser,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchUsers() {
  try {
    const response = yield call(getUsersList)
    yield put(getUsersListSuccess(response))
  } catch (error) {
    yield put(getUsersListFail(error))
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const response = yield call(getUserDetails, userId)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onAddNewUser({ payload: { user, history } }) {
  try {
    const response = yield call(addNewUser, user)
    yield put(addNewUserSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-users")
        }
      })
    }
  } catch (error) {
    yield put(addNewUserFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateUser({ payload: { id, user, history } }) {
  try {
    const response = yield call(updateUser, id, user)
    yield put(updateUserSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-users")
        }
      })
    }
  } catch (error) {
    yield put(updateUserFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onDeleteUser({ user }) {
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
    const response2 = yield call(getUsersList)
    yield put(getUsersListSuccess(response2))
    toast.success("User Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteUserFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS_LIST, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default userSaga
