import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  addNewProduct,
  getCategoryInProduct,
  getProductDetail,
  getSizeChartInProduct,
  getSizeInProduct,
  getSizesForSizeChartByTopOrBottom,
  getSubcategoryInProduct,
  updateProduct,
} from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const EditProduct = props => {
  //meta title
  document.title = "Edit Product"
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategoryInProduct())
    dispatch(getSizeInProduct())
    dispatch(getProductDetail(id))
  }, [dispatch])

  const categoryState = state => state.products.categories.data
  const category = useSelector(categoryState) || []

  const subcategoryState = state => state.products.subcategories.data
  const subcategory = useSelector(subcategoryState) || []

  const sizeState = state => state.products.sizes.data
  const size = useSelector(sizeState) || []

  const sizeChartState = state => state.products.sizeCharts.data
  const sizeChart = useSelector(sizeChartState) || []

  const productDataState = state => state.products.productDetail.data
  const productData = useSelector(productDataState) || []

  // const sizeTitles = size.map(item => item.size_title || "")

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      is_active: (productData && productData.is_active) || false,
      multiSize: (productData && productData.multiSize) || false,
      customization: (productData && productData.customization) || false,
      product_name: (productData && productData.product_name) || "",
      sku_code: (productData && productData.sku_code) || "",
      sacHsnCode: (productData && productData.sacHsnCode) || "",
      category_id: (productData && productData.category_id) || "",
      subcategory_id: (productData && productData.subcategory_id) || "",
      gender: (productData && productData.gender) || "",
      size_chart_id: (productData && productData.size_chart_id) || null,
      size_chart_id_top: (productData && productData.size_chart_id_top) || null,
      size_chart_id_bottom:
        (productData && productData.size_chart_id_bottom) || null,
      regular_price: (productData && productData.regular_price) || "",
      product_detail: (productData && productData.product_detail) || "",
      material_and_maintain:
        (productData && productData.material_and_maintain) || "",
      return_policy: (productData && productData.return_policy) || "",
      pockets: (productData && productData.pockets) || "",
      size_price:
        (productData &&
          productData?.size_price?.map(item => ({
            size_id: item.size_id || "",
            price: item.price || "",
          }))) ||
        [],
      size_price_top:
        (productData &&
          productData?.size_price_top?.map(item => ({
            size_id: item.size_id || "",
            price: item.price || "",
          }))) ||
        [],
      size_price_bottom:
        (productData &&
          productData?.size_price_bottom?.map(item => ({
            size_id: item.size_id || "",
            price: item.price || "",
          }))) ||
        [],
    },

    validationSchema: Yup.object({
      product_name: Yup.string().required("Please Enter Product Name"),
      sku_code: Yup.string().required("Please Enter SKU Code"),
      sacHsnCode: Yup.number().required("Please Enter SAC/ HSN Code").typeError("SAC / HSN Code Should be a Number"),
      category_id: Yup.string().required("Please Select Category"),
      subcategory_id: Yup.string().required("Please Select Subcategory"),
      gender: Yup.string().required("Please Select Gender"),
      regular_price: Yup.number()
        .required("Please Enter Regular Price")
        .typeError("Regular Price must be a number"),
      product_detail: Yup.string().required("Please Enter Product Description"),
      material_and_maintain: Yup.string().required(
        "Please Enter Regular Price"
      ),
      return_policy: Yup.string().required("Please Enter Return Policy"),
    }),

    onSubmit: async values => {
      dispatch(updateProduct(id, values, props.router.navigate))
    },
  })

  useEffect(() => {
    if (validation.values.category_id) {
      dispatch(getSubcategoryInProduct(validation.values.category_id))
    }
  }, [validation.values.category_id])

  useEffect(() => {
    dispatch(getSizesForSizeChartByTopOrBottom(validation.values.multiSize))
  }, [validation.values.multiSize])

  const [sizeTitles, setSizeTitles] = useState([])
  const [sizeTitlesTop, setSizeTitlesTop] = useState([])
  const [sizeTitlesBottom, setSizeTitlesBottom] = useState([])

  useEffect(() => {
    const sizeIds = validation.values.size_price.map(sp => sp.size_id)
    const filteredSizeTitles = sizeIds.map(sizeId => {
      const sizeOutput = size.find(s => s._id === sizeId)
      return sizeOutput ? sizeOutput.size_title : ""
    })
    setSizeTitles(filteredSizeTitles)
  }, [validation.values.size_price, size])

  useEffect(() => {
    const sizeIds = validation.values.size_price_top.map(sp => sp.size_id)
    const filteredSizeTitles = sizeIds.map(sizeId => {
      const sizeOutput = size.find(s => s._id === sizeId)
      return sizeOutput ? sizeOutput.size_title : ""
    })
    setSizeTitlesTop(filteredSizeTitles)
  }, [validation.values.size_price_top, size])

  useEffect(() => {
    const sizeIds = validation.values.size_price_bottom.map(sp => sp.size_id)
    const filteredSizeTitles = sizeIds.map(sizeId => {
      const sizeOutput = size.find(s => s._id === sizeId)
      return sizeOutput ? sizeOutput.size_title : ""
    })
    setSizeTitlesBottom(filteredSizeTitles)
  }, [validation.values.size_price_bottom, size])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-products" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Edit Product
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="product_name">Product Name*</Label>
                          <Input
                            id="product_name"
                            name="product_name"
                            type="text"
                            placeholder="Product Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.product_name &&
                              validation.errors.product_name
                                ? true
                                : false
                            }
                            value={validation.values.product_name}
                          />
                          {validation.touched.product_name &&
                          validation.errors.product_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.product_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="sku_code">SKU Code*</Label>
                          <Input
                            id="sku_code"
                            name="sku_code"
                            type="text"
                            placeholder="SKU Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.sku_code &&
                              validation.errors.sku_code
                                ? true
                                : false
                            }
                            value={validation.values.sku_code}
                          />
                          {validation.touched.sku_code &&
                          validation.errors.sku_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sku_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Category*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="category_id"
                            value={validation.values.category_id}
                            className={`form-select ${
                              validation.touched.category_id &&
                              validation.errors.category_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.category_id && (
                              <option>Select Category</option>
                            )}

                            {(category || []).map(item => (
                              <option key={item.id} value={item._id}>
                                {item.category_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.category_id &&
                          validation.errors.category_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.category_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Subcategory*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="subcategory_id"
                            value={validation.values.subcategory_id}
                            className={`form-select ${
                              validation.touched.subcategory_id &&
                              validation.errors.subcategory_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.subcategory_id && (
                              <option>Select Subcategory</option>
                            )}

                            {(subcategory || []).map(item => (
                              <option key={item.id} value={item._id}>
                                {item.subcategory_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.subcategory_id &&
                          validation.errors.subcategory_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.subcategory_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Gender*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="gender"
                            value={validation.values.gender}
                            className={`form-select ${
                              validation.touched.gender &&
                              validation.errors.gender
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.gender && (
                              <option>Select Gender</option>
                            )}
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="BOTH">Both</option>
                          </select>
                          {validation.touched.gender &&
                          validation.errors.gender ? (
                            <FormFeedback type="invalid">
                              {validation.errors.gender}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Pockets
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="pockets"
                            value={validation.values.pockets}
                            className={`form-select ${
                              validation.touched.pockets &&
                              validation.errors.pockets
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Number of Pockets</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                          {validation.touched.pockets &&
                          validation.errors.pockets ? (
                            <FormFeedback type="invalid">
                              {validation.errors.pockets}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="product_detail">
                            Product Detail*
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="product_detail"
                            name="product_detail"
                            placeholder="Product Description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.product_detail &&
                              validation.errors.product_detail
                                ? true
                                : false
                            }
                            value={validation.values.product_detail}
                          />
                          {validation.touched.product_detail &&
                          validation.errors.product_detail ? (
                            <FormFeedback type="invalid">
                              {validation.errors.product_detail}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Material & Maintain*
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="material_and_maintain"
                            placeholder="Material & Maintain"
                            name="material_and_maintain"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.material_and_maintain &&
                              validation.errors.material_and_maintain
                                ? true
                                : false
                            }
                            value={validation.values.material_and_maintain}
                          />
                          {validation.touched.material_and_maintain &&
                          validation.errors.material_and_maintain ? (
                            <FormFeedback type="invalid">
                              {validation.errors.material_and_maintain}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Return Policy*</Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="return_policy"
                            placeholder="Return Policy"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="return_policy"
                            invalid={
                              validation.touched.return_policy &&
                              validation.errors.return_policy
                                ? true
                                : false
                            }
                            value={validation.values.return_policy}
                          />
                          {validation.touched.return_policy &&
                          validation.errors.return_policy ? (
                            <FormFeedback type="invalid">
                              {validation.errors.return_policy}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="customization">Customization?</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="customization"
                              name="customization"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "customization",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(
                                validation?.values?.customization
                              )}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="customization"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label
                            className="control-label"
                            htmlFor="regular_price"
                          >
                            Regular Price*
                          </Label>
                          <Input
                            id="regular_price"
                            name="regular_price"
                            type="text"
                            className="form-control"
                            placeholder="Enter Regular Price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.regular_price &&
                              validation.errors.regular_price
                                ? true
                                : false
                            }
                            value={validation.values.regular_price}
                          />
                          {validation.touched.regular_price &&
                          validation.errors.regular_price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regular_price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label htmlFor="multiSize">Multisize?</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="multiSize"
                              name="multiSize"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "multiSize",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.multiSize)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="multiSize"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="sacHsnCode">SAC / HSN Code*</Label>
                          <Input
                            id="sacHsnCode"
                            name="sacHsnCode"
                            type="text"
                            placeholder="SAC / HSN Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.sacHsnCode &&
                              validation.errors.sacHsnCode
                                ? true
                                : false
                            }
                            value={validation.values.sacHsnCode}
                          />
                          {validation.touched.sacHsnCode &&
                          validation.errors.sacHsnCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sacHsnCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {validation.values.multiSize === false && (
                      <Row>
                        <Col>
                          <Label className="control-label mb-3">
                            Enter Sizes Price:
                          </Label>
                          {size &&
                            size?.map((row, key) => (
                              <Row key={row.size_id}>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label>Size</Label>
                                    <Input
                                      type="text"
                                      id={`size_id${key}`}
                                      name={`size_price[${key}].price`}
                                      disabled
                                      value={sizeTitles && sizeTitles[key]}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Label>Price</Label>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`price${key}`}
                                      name={`size_price[${key}].price`}
                                      placeholder="Enter Amount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.size_price[key]
                                          ?.price || ""
                                      }
                                      invalid={
                                        validation.touched.size_price?.[key]
                                          ?.price &&
                                        !!validation.errors.size_price?.[key]
                                          ?.price
                                      }
                                    />
                                    {validation.touched.size_price?.[key]
                                      ?.price &&
                                      validation.errors.size_price?.[key]
                                        ?.price && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.size_price[key]
                                              .price
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="formrow-category-Input">
                              Size Chart
                            </Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="size_chart_id"
                              value={validation.values.size_chart_id}
                              className={`form-select ${
                                validation.touched.size_chart_id &&
                                validation.errors.size_chart_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.size_chart_id && (
                                <option>Select Size Chart</option>
                              )}

                              {Array.isArray(sizeChart) &&
                                sizeChart.map(item => (
                                  <option key={item._id} value={item._id}>
                                    {item.size_chart_title}
                                  </option>
                                ))}
                            </select>
                            {validation.touched.size_chart_id &&
                            validation.errors.size_chart_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.size_chart_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {validation.values.multiSize === true && (
                      <>
                        <Row>
                          <Col>
                            <Label className="control-label mb-3">
                              Enter Top Sizes Price:
                            </Label>
                            {size &&
                              size?.map((row, key) => (
                                <Row key={row.size_id}>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Size</Label>
                                      <Input
                                        type="text"
                                        id={`size_id${key}`}
                                        name={`size_price_top[${key}].price`}
                                        disabled
                                        value={
                                          sizeTitlesTop && sizeTitlesTop[key]
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <Label>Price</Label>
                                    <div className="mb-3">
                                      <Input
                                        type="text"
                                        id={`price${key}`}
                                        name={`size_price_top[${key}].price`}
                                        placeholder="Enter Amount"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.size_price_top[key]
                                            ?.price || ""
                                        }
                                        invalid={
                                          validation.touched.size_price_top?.[
                                            key
                                          ]?.price &&
                                          !!validation.errors.size_price_top?.[
                                            key
                                          ]?.price
                                        }
                                      />
                                      {validation.touched.size_price_top?.[key]
                                        ?.price &&
                                        validation.errors.size_price_top?.[key]
                                          ?.price && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors.size_price_top[
                                                key
                                              ].price
                                            }
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-category-Input">
                                Size Chart Top
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="size_chart_id_top"
                                value={validation.values.size_chart_id_top}
                                className={`form-select ${
                                  validation.touched.size_chart_id_top &&
                                  validation.errors.size_chart_id_top
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.size_chart_id_top && (
                                  <option>Select Size Chart</option>
                                )}

                                {sizeChart &&
                                  sizeChart?.top?.map(item => (
                                    <option key={item.id} value={item._id}>
                                      {item.size_chart_title}
                                    </option>
                                  ))}
                              </select>
                              {validation.touched.size_chart_id_top &&
                              validation.errors.size_chart_id_top ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.size_chart_id_top}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label className="control-label mb-3">
                              Enter Bottom Sizes Price:
                            </Label>
                            {size &&
                              size?.map((row, key) => (
                                <Row key={row.size_id}>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Size</Label>
                                      <Input
                                        type="text"
                                        id={`size_id${key}`}
                                        name={`size_price_bottom[${key}].price`}
                                        disabled
                                        value={
                                          sizeTitlesBottom &&
                                          sizeTitlesBottom[key]
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <Label>Price</Label>
                                    <div className="mb-3">
                                      <Input
                                        type="text"
                                        id={`price${key}`}
                                        name={`size_price_bottom[${key}].price`}
                                        placeholder="Enter Amount"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.size_price_bottom[
                                            key
                                          ]?.price || ""
                                        }
                                        invalid={
                                          validation.touched
                                            .size_price_bottom?.[key]?.price &&
                                          !!validation.errors
                                            .size_price_bottom?.[key]?.price
                                        }
                                      />
                                      {validation.touched.size_price_bottom?.[
                                        key
                                      ]?.price &&
                                        validation.errors.size_price_bottom?.[
                                          key
                                        ]?.price && (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors
                                                .size_price_bottom[key].price
                                            }
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-category-Input">
                                Size Chart Bottom
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="size_chart_id_bottom"
                                value={validation.values.size_chart_id_bottom}
                                className={`form-select ${
                                  validation.touched.size_chart_id_bottom &&
                                  validation.errors.size_chart_id_bottom
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.size_chart_id_bottom && (
                                  <option>Select Size Chart</option>
                                )}

                                {sizeChart &&
                                  sizeChart?.bottom?.map(item => (
                                    <option key={item.id} value={item._id}>
                                      {item.size_chart_title}
                                    </option>
                                  ))}
                              </select>
                              {validation.touched.size_chart_id_bottom &&
                              validation.errors.size_chart_id_bottom ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.size_chart_id_bottom}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md"
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Edit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditProduct)

EditProduct.propTypes = {
  history: PropTypes.object,
}
