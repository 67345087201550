export const GET_SIZE_CHART_LIST = "GET_SIZE_CHART_LIST"
export const GET_SIZE_CHART_LIST_SUCCESS = "GET_SIZE_CHART_LIST_SUCCESS"
export const GET_SIZE_CHART_LIST_FAIL = "GET_SIZE_CHART_LIST_FAIL"

export const GET_SIZE_CHART_DETAIL = "GET_SIZE_CHART_DETAIL"
export const GET_SIZE_CHART_DETAIL_SUCCESS = "GET_SIZE_CHART_DETAIL_SUCCESS"
export const GET_SIZE_CHART_DETAIL_FAIL = "GET_SIZE_CHART_DETAIL_FAIL"

export const ADD_NEW_SIZE_CHART = "ADD_NEW_SIZE_CHART"
export const ADD_NEW_SIZE_CHART_SUCCESS = "ADD_NEW_SIZE_CHART_SUCCESS"
export const ADD_NEW_SIZE_CHART_FAIL = "ADD_NEW_SIZE_CHART_FAIL"

export const UPDATE_SIZE_CHART = "UPDATE_SIZE_CHART"
export const UPDATE_SIZE_CHART_SUCCESS = "UPDATE_SIZE_CHART_SUCCESS"
export const UPDATE_SIZE_CHART_FAIL = "UPDATE_SIZE_CHART_FAIL"

export const DELETE_SIZE_CHART = "DELETE_SIZE_CHART"
export const DELETE_SIZE_CHART_SUCCESS = "DELETE_SIZE_CHART_SUCCESS"
export const DELETE_SIZE_CHART_FAIL = "DELETE_SIZE_CHART_FAIL"

export const GET_SIZES_FOR_SIZE_CHART = "GET_SIZES_FOR_SIZE_CHART"
export const GET_SIZES_FOR_SIZE_CHART_SUCCESS = "GET_SIZES_FOR_SIZE_CHART_SUCCESS"
export const GET_SIZES_FOR_SIZE_CHART_FAIL = "GET_SIZES_FOR_SIZE_CHART_FAIL"
