import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_DASHBOARD_DATA } from "./actionTypes"
import { getDashboardDataFail, getDashboardDataSuccess } from "./actions"
import { getDashboardData } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchDashboardData({ payload: { data, toggle, history } }) {
  try {
    const response = yield call(getDashboardData, data)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
}

export default dashboardSaga
