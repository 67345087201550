import {
  ADD_NEW_SUBCATEGORY,
  ADD_NEW_SUBCATEGORY_SUCCESS,
  ADD_NEW_SUBCATEGORY_FAIL,
  GET_SUBCATEGORIES_LIST,
  GET_SUBCATEGORIES_LIST_SUCCESS,
  GET_SUBCATEGORIES_LIST_FAIL,
  UPDATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY_DETAIL,
  GET_SUBCATEGORY_DETAIL_SUCCESS,
  GET_SUBCATEGORY_DETAIL_FAIL,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL,
} from "./actionTypes"

export const getSubCategoriesList = () => ({
  type: GET_SUBCATEGORIES_LIST,
})

export const getSubCategoriesListSuccess = categories => ({
  type: GET_SUBCATEGORIES_LIST_SUCCESS,
  payload: categories,
})

export const getSubCategoriesListFail = error => ({
  type: GET_SUBCATEGORIES_LIST_FAIL,
  payload: error,
})

export const getSubCategoryDetail = categoryId => ({
  type: GET_SUBCATEGORY_DETAIL,
  categoryId,
})

export const getSubCategoryDetailSuccess = categoryDetails => ({
  type: GET_SUBCATEGORY_DETAIL_SUCCESS,
  payload: categoryDetails,
})

export const getSubCategoryDetailFail = error => ({
  type: GET_SUBCATEGORY_DETAIL_FAIL,
  payload: error,
})

export const addNewSubCategory = (category, history) => ({
  type: ADD_NEW_SUBCATEGORY,
  payload: { category, history },
})

export const addNewSubCategorySuccess = category => ({
  type: ADD_NEW_SUBCATEGORY_SUCCESS,
  payload: category,
})

export const addNewSubCategoryFail = error => ({
  type: ADD_NEW_SUBCATEGORY_FAIL,
  payload: error,
})

export const updateSubCategory = (id, category, history) => ({
  type: UPDATE_SUBCATEGORY,
  payload: { id, category, history },
})

export const updateSubCategorySuccess = category => ({
  type: UPDATE_SUBCATEGORY_SUCCESS,
  payload: category,
})

export const updateSubCategoryFail = error => ({
  type: UPDATE_SUBCATEGORY_FAIL,
  payload: error,
})

export const deleteSubCategory = category => ({
  type: DELETE_SUBCATEGORY,
  category,
})

export const deleteSubCategorySuccess = category => ({
  type: DELETE_SUBCATEGORY_SUCCESS,
  payload: category,
})

export const deleteSubCategoryFail = error => ({
  type: DELETE_SUBCATEGORY_FAIL,
  payload: error,
})

export const getCategoriesListForSubCategory = () => ({
  type: GET_CATEGORIES_LIST_FOR_SUBCATEGORY,
})

export const getCategoriesListForSubCategorySuccess = categories => ({
  type: GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS,
  payload: categories,
})

export const getCategoriesListForSubCategoryFail = error => ({
  type: GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL,
  payload: error,
})
