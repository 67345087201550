import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, CardTitle } from "reactstrap"
import TypeOfRequest from "./count"
import Category from "./activity"
import Graph from "./graph"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { getDashboardData } from "store/dashboard/actions"

const Dashboard = props => {
  const dispatch = useDispatch()
  const [user] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [activityFilter, setActivityFilter] = useState("")
  const [graphFilter, setGraphFilter] = useState("")
  const [formData, setFormData] = useState({
    activityFilter: "",
    graphFilter: "",
  })

  useEffect(() => {
    setFormData({
      activityFilter: activityFilter.activityFilter,
      graphFilter: graphFilter.graphFilter,
    })
  }, [activityFilter, graphFilter])

  useEffect(() => {
    if (formData.activityFilter && formData.graphFilter) {
      dispatch(getDashboardData(formData))
    }
  }, [dispatch, formData])

  const countState = state => state?.dashboard?.dashboardData?.data?.count
  const count = useSelector(countState) || []

  const activityState = state => state?.dashboard?.dashboardData?.data?.activity
  const activity = useSelector(activityState) || []

  const graphState = state => state?.dashboard?.dashboardData?.data?.graph
  const graph = useSelector(graphState) || []

  //meta title
  document.title = "Dashboard"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={12}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-home font-size-20"
                    style={{ color: "grey" }}
                  ></i>
                  Dashboard
                </div>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <TypeOfRequest count={count}></TypeOfRequest>
          </Row>
          <Row>
            <Col lg={6}>
              <Category setData={setActivityFilter} detail={activity} />
            </Col>
            <Col lg={6}>
              <Graph setData={setGraphFilter} detail={graph} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
