import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Users from "./user/reducer"
import colors from "./color/reducer"
import sizes from "./size/reducer"
import categories from "./category/reducer"
import products from "./product/reducer"
import subcategories from "./subcategory/reducer"
import sizeChart from "./sizechart/reducer"
import newsLetter from "./newsletter/reducer"
import ecomusers from "./ecomusers/reducer"
import orders from "./orders/reducer"
import contactUs from "./contactUs/reducer"
import dashboard from "./dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Profile,
  Users,
  colors,
  sizes,
  sizeChart,
  categories,
  subcategories,
  products,
  newsLetter,
  ecomusers,
  orders,
  contactUs,
  dashboard,
})

export default rootReducer
