const formatNumberWithCommas = number => {
  if (!number) {
    return number
  }

  let numStr = String(number)

  let parts = numStr.split(".")

  let integerPart = parts[0]
  let lastThreeDigits = integerPart.slice(-3)
  let remainingDigits = integerPart.slice(0, -3)
  let formattedInteger =
    remainingDigits !== ""
      ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits
      : lastThreeDigits

  return formattedInteger
}

export default formatNumberWithCommas
